@use "./boilerplate" as var;

/**NavBar  *******************************************/

.h-mobile {
  height: calc(100vh - 70px);
}
/**Nav Button*/

.nav__buttons-btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  height: 4rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#0f172a, #0f172a),
    linear-gradient(
      137.48deg,
      #fbbf24 10%,
      #ef4444 45%,
      #f3f4f6 67%,
      #0f172a 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  @media screen and (max-width: 890px) {
    display: none;
  }
}

#container-stars {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  font-size: 1.4rem;
  color: #f3f4f6;
  text-shadow: 0 0 4px #f3f4f6;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.btn1-circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.btn1-circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.btn1-circle:nth-of-type(2) {
  background: #7664ff9e;
}

.nav__buttons-btn1:hover #container-stars {
  z-index: 1;
  background-color: #0f172a;
}

.nav__buttons-btn1:hover strong {
  color: #fbbf24;
  text-shadow: 0 0 4px #fbbf24;
}

.nav__buttons-btn1:hover {
  transform: scale(1.1);
}

.nav__buttons-btn1:active {
  border: double 4px #fbbf24;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.nav__buttons-btn1:active .btn1-circle {
  background: #fbbf24;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/**menu items hover*/
.underline-gradient {
  background: linear-gradient(
    94deg,
    rgb(199, 31, 56) 0%,
    rgb(199, 31, 56) 100%
  );

  background-size: 0% 3px;
  background-repeat: no-repeat;
  background-position: right 25px;
  transition: background-size 300ms ease;
}

.underline-gradient:hover {
  background-size: 100% 3px;
}
.underline-gradient.active:hover {
  background-size: 0% 3px;
}

//!--  Dark Mode Button    -------------------
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 64px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9b522;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  border-radius: 20px;
  left: 2px;
  bottom: 2px;
  z-index: 2;
  background-color: #e8e8e8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sun svg {
  position: absolute;
  top: 6px;
  left: 36px;
  z-index: 1;
  width: 24px;
  height: 24px;
    fill: #fef2f2;

}

.moon svg {
  fill: #fef2f2;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  width: 24px;
  height: 24px;
}

/* .switch:hover */
.sun svg {
  -webkit-animation: rotate 15s linear infinite;
  animation: rotate 15s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* .switch:hover */
.moon svg {
  -webkit-animation: tilt 5s linear infinite;
  animation: tilt 5s linear infinite;
}

@-webkit-keyframes tilt {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  75% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes tilt {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  75% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.dark-mode-checkbox:checked + .slider {
  background-color: #6366f1;
}

.dark-mode-checkbox:focus + .slider {
  -webkit-box-shadow: 0 0 1px #6366f1;
  box-shadow: 0 0 1px #6366f1;
}

.dark-mode-checkbox:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

@supports selector(:focus-visible) {
  .theme__toggle:focus {
    box-shadow: 0 0 0 0.125em var(--primaryT);
  }

  .theme__toggle:focus-visible {
    box-shadow: 0 0 0 0.125em var(--primary);
  }
}

/**Hero  *******************************************/

/**Hero Button*/

.hero-button-container {
  align-items: center;
  justify-content: center;
  //transform: translate(0%,25%);
}

.hero-button-container button {
  width: 15rem;
  height: 5rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3f4f6;
  border-radius: 15px;
  z-index: 3;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  background-color: #fbbf24;
  transition: 0.4s ease-in-out;
  z-index: 3;
  font-weight: bold;
  font-size: 2rem;
}

.satalite {
  position: relative;
  left: -1em;
  top: -3em;
  animation: 4s around infinite;
  transition: 0.4s ease-in-out;
}

@keyframes around {
  0% {
    z-index: 5;
    transform: translateY(-3.5em) translateX(8.5em);
  }

  50% {
    transform: translateX(1em) translateY(3.5em);
  }

  100% {
    transform: translateY(-3.5em) translateX(8.5em);
    z-index: -2;
  }
}

.hero-button-container button:hover {
  color: #fbbf24;
  background-color: #f3f4f6;
}

.hero-button-container button:hover + .satalite {
  opacity: 0;
}

.hero-button-container button:active {
  transform: scale(0.9);
}

/**Fade in Class*/

.fade {
  //transform:translateY(50px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

/**Why Us *******************************************/

body {
  font-family: var.$menu;
}

.feature p {
  display: none;
}

.feature.active p {
  display: block;
}

.feature.active {
  grid-column: 1 / span 6;
  grid-row: 1;
  order: 0;
  height: auto;
}

.feature.is-inactive {
  grid-column: span 2;
}

@media screen and (max-width: 768px) {
  .feature.active {
    grid-column: 1 / span 3;
    grid-row: 1;
    order: 0;
    height: auto;
  }

  .feature.is-inactive {
    grid-column: span 3;
  }
}

/**Courses *******************************/

.footer-auto-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 7rem;
  justify-items: end;
  place-content: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    justify-items: center;
  }
}
