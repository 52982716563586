.modalCustomClass {
  /* Customize modal content styles */
  padding: 20px;
}

/* Target the modal container */
:global(.ant-modal-content) {
  border-radius: 12px;
  padding: 24px;
}

/* Target the modal header */
:global(.ant-modal-header) {
  border-bottom: none;
  padding: 0;
}

/* Target the modal body */
:global(.ant-modal-body) {
  padding: 20px 0;
}

/* Target the modal close icon */
:global(.ant-modal-close) {
  color: #666;
}
