@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;800&display=swap");

//fonts
$heading: "Arapey", serif;
$body: "Maitree", serif;
$menu: "Work Sans", sans-serif;

//colors

:root {
  $purple: #7664ff;
  $light-purple: #bdadfc;
  $dark-purple: #77298c;
  $pink: #f1ccfa;
  $blue: #0c145e;
  $light-gray: #eaebf5;
  $dark-gray: #b2b8e8;

  $rose: #f1468e;
  $light-rose: #df90bd;
  $bg: rgb(255, 201, 169);
  $white: #ffffff;
  $black: #000000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cairo", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  //background-color: #f3f4f6;
  //color:#7664ff ;
  font-size: 1.6rem;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.width {
  width: min(90%, 1300px);
  margin-inline: auto;
}


/*---------------------------------------------------*/
/*	 AALI 
/*---------------------------------------------------*/
.aali_container {
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 0px 40px;
  position: relative;
  clear: both;
}

@media (max-width: 1400px) {
  .aali_container {
    max-width: 1070px;
  }

}

@media (max-width: 1040px) {
  .aali_container {
    padding: 0px 20px;
  }

}

.aali_tm_copyright {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #3c3c3c;
  padding: 120px 0px 65px 0px;
  position: relative;
  margin-top: 80px;
}

.aali_tm_copyright .copyright_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
}

.aali_tm_copyright .top {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 112px;
}

.aali_tm_copyright .logo img {
  max-width: 175px;
}

.aali_tm_copyright .social {
  display: flex;
  align-items: center;
}

.aali_tm_copyright .social ul {
  margin: 0px;
  list-style-type: none;
}

.aali_tm_copyright .social ul li {
  margin: 0px 7px 0px 0px;
  display: inline-block;
}

.aali_tm_copyright .social ul li:last-child {
  margin-right: 0px;
}

.aali_tm_copyright .social ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 48px;
  color: rgb(255 0 0);

  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.aali_tm_copyright .social ul li a:hover {
  background-color: rgb(255 0 0);
  color: #fff;
}

.aali_tm_copyright .social span {
  padding-right: 30px;
  // font-family: "Josefin Sans";
  font-weight: 700;
  color: #fff;
  display: inline-block;
  position: relative;
  top: 3px;
}

.aali_tm_copyright .bottom {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aali_tm_copyright .bottom .left p {
  color: #b6b2bf;
}

.aali_tm_copyright .bottom .left a {
  color: #b6b2bf;
  text-decoration: none;
  font-weight: 700;

  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.aali_tm_copyright .bottom .left a:hover {
  color: #fff;
}

.aali_tm_copyright .bottom .right ul {
  margin: 0px;
  list-style-type: none;
}

.aali_tm_copyright .bottom .right ul li {
  margin: 0px 40px 0px 0px;
  display: inline-block;
}

.aali_tm_copyright .bottom .right ul li:last-child {
  margin-right: 0px;
}

.aali_tm_copyright .bottom .right ul li a {
  text-decoration: none;
  color: #b6b2bf;

  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.aali_tm_copyright .bottom .right ul li a:hover {
  color: #fff;
}

.aali_tm_copyright .border {
  position: absolute;
  width: 320px;
  height: 300px;
  border: 6px solid #fff;
  top: -200px;
  left: -45px;
}

.aali_tm_copyright .square {
  position: absolute;
  width: 84px;
  height: 143px;
  top: -77px;
  right: 60px;
  background-color: rgb(255 0 0);
}