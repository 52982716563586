.signin-inputs,
.submit,
textarea {
  width: 100%;
  text-align: end;
  font-size: 1.5rem;
  font-weight: 600;
  padding-right: 1rem;
  border-radius: 1rem;
  transition: box-shadow 0.1s ease-in-out;
  background-color: transparent;
  border: 2px solid #ef4444;
  color: #ef4444;
}

.signin-inputs,
.submit {
  height: 3.4rem;
}

.submit {
  text-align: center;
}

.signin-inputs::placeholder,
textarea::placeholder {
  color: #ef4444;
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 600;
}

.signin-inputs:focus,
textarea:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px #fbbf24;
  color: #fbbf24;
  background-color: #fee2e2;

  &::placeholder {
    color: #fbbf24;
  }
}

label {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  width: 100%;
  text-align: end;
}

.submit {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1.7rem;
  font-weight: 700;
}

.submit:active {
  transform: scale(0.9);
}

.submit:disabled {
  border: 2px solid #ef444466;
  color: #ef444466;
}

.submit:disabled:active {
  transform: scale(1);
}

select {
  width: 100%;
  height: 3.4rem;
  padding-right: 1rem;
  border-radius: 1rem;
  transition: box-shadow 0.1s ease-in-out;
  background-color: transparent;
  border: 2px solid #ef4444;
  color: #ef4444;

  option {
    text-align: end;
  }
}

select:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px #fbbf24;
  color: #fbbf24;
  background-color: #685d5d;
}

input[type="file"] {
  height: 3.4rem;
  padding: 0.4rem;
  font-size: 1.2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.question-submit {
  width: 250px;
}

//!------ permissions checkbox ---------

.checkbox-wrapper {
  position: relative;
}

.checkbox-wrapper>svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper .cbx {
  width: 24px;
  height: 24px;
  top: calc(100px - 12px);
  left: calc(100px - 12px);
}

.checkbox-wrapper .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #c71f38;
  background-color: #fba6a66f;
  border-radius: 50%;
}

.checkbox-wrapper .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

.checkbox-wrapper .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper .cbx svg path {
  stroke: #fef2f2;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  -webkit-transition: stroke-dashoffset 0.3s ease;
  transition: stroke-dashoffset 0.3s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.checkbox-wrapper .cbx input:checked+label {
  -webkit-animation: splash-12 0.6s ease forwards;
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper .cbx input:checked+label+svg path {
  stroke-dashoffset: 0;
}

@-webkit-keyframes splash-12 {
  40% {
    background: #c71f38;
    -webkit-box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
    box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
  }

  100% {
    background: #c71f38;
    -webkit-box-shadow: 0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: #c71f38;
    -webkit-box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
    box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
  }

  100% {
    background: #c71f38;
    -webkit-box-shadow: 0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}


// new
.cardS1 {
  // max-width: 500px;
  background: #F8F9FD;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
  border-radius: 40px;
  padding: 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(203, 2, 32, 0.8) 0px 30px 30px -20px;
  margin: 20px;
}

.form {
  margin-top: 20px;
}

.form .input {
  width: 100%;
  min-height: 50px;
  background: white;
  border: none;
  padding: 0px 20px;
  border-radius: 20px;
  box-shadow: #cff0ff 0px 10px 10px -5px;
  border-inline: 2px solid transparent;
  text-align: end;
}

.form .input::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .input::placeholder {
  color: rgb(170, 170, 170);
}

.form .input:focus {
  outline: none;
  border-inline: 2px solid rgb(255 0 0);
}

.form .forgot-password {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

.form .forgot-password a {
  font-size: 11px;
  color: rgb(255 0 0);
  text-decoration: none;
}

.form .login-button {
  display: block;
  width: 100%;
  font-weight: bold;
  background: linear-gradient(45deg, rgb(255 0 0) 0%, rgba(203, 2, 32, 0.5));
  color: white;
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: rgba(203, 2, 32, 0.8);
  border: none;
  transition: all 0.3s ease-in-out;



  &:hover {
    background: #fff;
    color: rgb(255 0 0);
    border: 1px solid rgba(203, 2, 32, 1);
  }
}

.form .login-button:hover {
  transform: scale(1.03);
}

.form .login-button:active {
  transform: scale(0.95);
}


.agreement {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.agreement a {
  text-decoration: none;
  color: rgb(255 0 0);
}